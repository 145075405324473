import { useQuery } from "@tanstack/react-query"
import { konverterFraMinutterTilMillisekunder } from "../../../../utils/konverterFraMinutterTilMillisekunder"
import { ApiError } from "../../../core/error-handling/ApiError"
import { api } from "../../../shared-api/router"
import { BortfestetDetaljertEiendom, EidDetaljertEiendom } from "../types/DetaljertEiendom"

export const useEiendom = (matrikkelenhetId: string | undefined) => {
  return useQuery<EidDetaljertEiendom | BortfestetDetaljertEiendom, ApiError>({
    queryKey: ["eiendom", matrikkelenhetId],
    staleTime: konverterFraMinutterTilMillisekunder(15),
    queryFn: () => api.eiendom.hentEiendom(matrikkelenhetId!),
    throwOnError: true,
    enabled: !!matrikkelenhetId,
  })
}
