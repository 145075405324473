import {
  Grid,
  GridItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@kvib/react"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { formaterStorForbokstav } from "../../../../utils/formateringUtils"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Bruksenhet } from "../../detaljert-eiendom/types/DetaljertEiendom"
import useOpprettEgenregistrering from "../api/useOpprettEgenregistering"
import { useEgenregistreringForm } from "../hooks/useEgenregistreringForm"
import useErTomVerdi from "../hooks/useErTomVerdi"
import { EgenregistreringBygning } from "../types/Egenregistrering"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "../types/schema"
import { felterForEgenregistrering, ikkeRegistrertVerdi, visEgenregistrertVerdi } from "../utils"
import EgenregistreringBekreftelseModal from "./EgenregistreringBekreftelseModal"
import { EgenregistreringKnapp } from "./EgenregistreringKnapp"
import EgenregistreringModal from "./EgenregistreringModal"
import Registreringsinfo from "./Registreringsinfo"

type ModalType = "ettFelt" | "bekreftelse"

const EgenregistreringForm = ({
  dineBruksenheterIBygning,
  egenregistreringForBygning,
}: {
  dineBruksenheterIBygning: Bruksenhet[]
  egenregistreringForBygning: EgenregistreringBygning
}) => {
  const { t } = useTranslation("egenregistrering")
  const form = useEgenregistreringForm(egenregistreringForBygning)
  const queryClient = useQueryClient()
  const isTomVerdi = useErTomVerdi()
  const { mutate: sendEgenregistrering, isPending } = useOpprettEgenregistrering()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [inputField, setInputField] = useState<
    { key: Felter; label: FelterLabels; icon: IkonForFelt } | undefined
  >(undefined)

  const [modalType, setModalType] = useState<ModalType | undefined>(undefined)
  const [submittedField, setSubmittedField] = useState<
    { key: Felter; label: FelterLabels; icon: IkonForFelt } | undefined
  >(undefined)

  const handleOpenModal = (
    type: ModalType,
    felt?: { key: Felter; label: FelterLabels; icon: IkonForFelt },
  ) => {
    setModalType(type)
    if (type === "ettFelt" && felt) {
      setInputField(felt)
    }
    onOpen()
  }

  const handleCloseModal = () => {
    setInputField(undefined)
    setModalType(undefined)
    onClose()
  }

  const handleSubmit = (oppdatertEgenregistrering: EgenregistreringSchema) => {
    const muteringsobjekt = {
      bygningId: egenregistreringForBygning.bygningId,
      bruksenhetsId: dineBruksenheterIBygning[0].bruksenhetsId,
      egenregistrering: oppdatertEgenregistrering,
    }
    setSubmittedField(inputField)

    sendEgenregistrering(muteringsobjekt, {
      onSuccess: () => {
        handleCloseModal()
        setModalType("bekreftelse")
        onOpen()

        queryClient.invalidateQueries({
          queryKey: ["egenregistrering", egenregistreringForBygning.bygningId],
        })
      },
    })
  }
  const bruksenhetsnummer = dineBruksenheterIBygning[0].erUnummerert
    ? "Unummerert brukseneht"
    : dineBruksenheterIBygning[0].bruksenhetsnummer
  const tekstForEgenregistreringsknapp = (felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }): string => {
    if (
      visEgenregistrertVerdi(
        felt.key,
        egenregistreringForBygning,
        dineBruksenheterIBygning[0].bruksenhetsId,
      ) === ikkeRegistrertVerdi
    ) {
      return formaterStorForbokstav(t("tekst_registrer_verdi_knapp"))
    }

    return formaterStorForbokstav(t("tekst_endre_verdi_knapp"))
  }

  return (
    <FormProvider {...form}>
      <TableContainer border="2px" borderColor="green">
        <form
          onSubmit={form.handleSubmit(
            handleSubmit,
            //handleSubmit blir bare kalt dersom valideringen i formet er vellykket.
            //Dersom man har glemt å vise erroren i UIet, vil ingenting tilsynelatende skje når du submitter.
            // onInvalid-callbacket til form.handleSubmit() håndterer dette tilfellet, og er en debug-hjelp til utvikler når mans gjør store strukturelle endringer i formet.
            err => {
              throw err
            },
          )}
        >
          <Table colorScheme="green">
            <Thead>
              <Tr>
                <Th>{`Registrert av eier for:  ${bruksenhetsnummer}`}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {felterForEgenregistrering.map(felt => (
                <Tr key={felt.key} h="80px">
                  <Td>
                    <Grid templateColumns="1fr 1fr" gap="16px" justifyContent="space-between">
                      <GridItem>
                        <Brødtekst fontWeight="bold">
                          {visEgenregistrertVerdi(
                            felt.key,
                            egenregistreringForBygning,
                            dineBruksenheterIBygning[0].bruksenhetsId,
                          )}
                        </Brødtekst>
                        <GridItem mt="4px">
                          {visEgenregistrertVerdi(
                            felt.key,
                            egenregistreringForBygning,
                            dineBruksenheterIBygning[0].bruksenhetsId,
                          ) !== ikkeRegistrertVerdi && <Registreringsinfo feltKey={felt.key} />}
                        </GridItem>
                      </GridItem>
                      <GridItem textAlign="right">
                        <EgenregistreringKnapp
                          data-cy={`egenregistrering-${felt.key}`}
                          icon={isTomVerdi(felt.key) ? "add" : "edit"}
                          onClick={() => handleOpenModal("ettFelt", felt)}
                        >
                          {tekstForEgenregistreringsknapp(felt)}
                        </EgenregistreringKnapp>
                      </GridItem>
                    </Grid>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </form>
      </TableContainer>

      {modalType === "ettFelt" && inputField && (
        <EgenregistreringModal
          felt={inputField}
          isOpen={isOpen}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          isLoading={isPending}
        />
      )}

      {modalType === "bekreftelse" && submittedField && (
        <EgenregistreringBekreftelseModal
          isOpen={isOpen}
          onClose={handleCloseModal}
          isSuccess
          egenregistrertVerdi={submittedField}
        />
      )}
    </FormProvider>
  )
}

export default EgenregistreringForm
