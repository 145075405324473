import {
  Box,
  Divider,
  Flex,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@kvib/react"
import { useTranslation } from "react-i18next"
import { formaterMedKommaOgOg } from "../../../utils/formateringUtils"
import Section from "../../core/components/layout/Section"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { Overskrift } from "../../core/text/components/Overskrift"
import BygningstypeOgVegadresseOverskrift from "../detaljert-eiendom/bygningsdetaljer/components/BygningstypeOgAdresseOverskrift"
import { getVegadresserPåBygning } from "../detaljert-eiendom/bygningsdetaljer/utils/bygningerUtils"
import useCurrentBygning from "../detaljert-eiendom/hooks/useCurrentBygning"
import useCurrentEiendom from "../detaljert-eiendom/hooks/useCurrentEiendom"
import EgenregistreringForm from "./components/EgenregistreringForm"
import { IkonOgFeltLabel } from "./components/IkonOgFeltLabel"
import { VisMatrikkelregistrerteVerdier } from "./components/VisMatrikkelregistrerteVerdier"
import { EgenregistreringBygning } from "./types/Egenregistrering"
import { felterForEgenregistrering } from "./utils"

const EgenregistrerteOpplysninger = ({
  egenregistreringForBygning,
}: {
  egenregistreringForBygning: EgenregistreringBygning
}) => {
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning()
  const { eiendom } = useCurrentEiendom()
  const { t } = useTranslation("egenregistrering")

  if (!currentBygning || !eiendom || !dineBruksenheterIBygning || !eiendom.adresser.vegadresser)
    return null

  const vegadresseForBygning = getVegadresserPåBygning(
    eiendom.adresser.vegadresser,
    dineBruksenheterIBygning.map(enhet => enhet.bruksenhetsId),
  )

  const bruksenhetsnummer = dineBruksenheterIBygning.map(enhet => enhet.bruksenhetsnummer)
  const formatertBruksenhetsnummer = formaterMedKommaOgOg(bruksenhetsnummer)
  const erSeksjon = eiendom.matrikkelenhetstype === "Eierseksjon"

  return (
    <Section mb="24px">
      <Overskrift mb="50px" variant="h1">
        {t("egenregistrering_sidetittel")}
      </Overskrift>
      <BygningstypeOgVegadresseOverskrift
        bygningstype={currentBygning.bygningstype}
        erSeksjon={erSeksjon}
        vegadresse={vegadresseForBygning}
        bruksenhetsnummer={formatertBruksenhetsnummer}
      />
      {erSeksjon && (
        <Box p="20px" mt="24px" bg="blue.50" borderRadius="md" maxW="60%">
          <Brødtekst>{t("eierseksjon_registrering_hjelpetekst_i_infoboks")}</Brødtekst>
        </Box>
      )}
      <Divider mb="36px" mt="36px" />
      <Grid templateColumns="1fr 1fr" gap={6}>
        <Flex>
          <TableContainer as={Flex}>
            <Table colorScheme="green">
              <Thead>
                <Tr>
                  <Th>Opplysning</Th>
                </Tr>
              </Thead>
              <Tbody>
                {felterForEgenregistrering.map(felt => (
                  <Tr key={felt.key}>
                    <Td h="80px">
                      <IkonOgFeltLabel felt={felt} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <VisMatrikkelregistrerteVerdier />
        </Flex>
        <EgenregistreringForm
          egenregistreringForBygning={egenregistreringForBygning}
          dineBruksenheterIBygning={dineBruksenheterIBygning}
        />
      </Grid>
    </Section>
  )
}

export default EgenregistrerteOpplysninger
