import { Box, Button, Divider, Flex, Heading, Switch } from "@kvib/react"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { ConsentContext } from "../context/ConsentContext"

const InformasjonskapslerOversikt = ({
  setShow,
}: {
  setShow: Dispatch<SetStateAction<boolean>>
}) => {
  const { consent, dispatch } = useContext(ConsentContext)

  const [isSwitchOn, setIsSwitchOn] = useState(consent.consentGiven)

  const { t } = useTranslation("cookiebanner")

  return (
    <Box mt={4}>
      <Box mt={8}>
        <Heading size="sm">{t("nodvendige_informasjonskapsler_overskrift")}</Heading>
        <Brødtekst>{t("nodvendige_informasjonskapsler_info")}</Brødtekst>
      </Box>
      <Divider mt={4} mb={4} borderWidth="1px" />
      <Box>
        <Heading size="sm">{t("webanalyse")}</Heading>
        <Flex dir="row" alignItems="center">
          <Brødtekst pr={10}>{t("webanalyse_info")}</Brødtekst>
          <Switch
            defaultChecked={consent.consentGiven}
            onChange={() => setIsSwitchOn(!isSwitchOn)}
          />
        </Flex>
      </Box>
      <Button
        variant="primary"
        mt={7}
        size="md"
        onClick={() => {
          setShow(false)
          if (isSwitchOn) {
            dispatch({ type: "GIVE_CONSENT" })
          } else {
            dispatch({ type: "DECLINE_CONSENT" })
          }
        }}
      >
        {t("lagre_valg")}
      </Button>
    </Box>
  )
}

export default InformasjonskapslerOversikt
