import { Box, Divider, Skeleton, Stack } from "@kvib/react"
import { t } from "i18next"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"
import KartutsnittForDetaljertEiendom from "../../../kartutsnitt/components/KartutsnittForDetaljertEiendom"
import { formaterAdresseForEiendom } from "../../eiendomsoversikt/utils/adresseForEiendomUtils"
import { erBortfestet } from "../api/eiendom"
import { Bygning } from "../bygningsdetaljer/types/Bygning"
import { hentBygningstype } from "../bygningsdetaljer/utils/bygningstypeUtil"
import { BortfestetDetaljertEiendom, EidDetaljertEiendom } from "../types/DetaljertEiendom"
import { BygningstyperDetalj } from "./BygningstyperDetalj"
import FestegrunnerPåEiendomAccordion from "./FestegrunnerPåEiendomAccordion"
import NøkkelinfoOmEiendommen from "./NøkkelinfoOmEiendommen"
import TeigOgArealInfo from "./TeigOgArealInfo"

const Eiendomsdetaljer = ({
  eiendom,
}: {
  eiendom: EidDetaljertEiendom | BortfestetDetaljertEiendom | undefined
}) => {
  const marginBottomForOverskrift = "8px"

  const adresse =
    eiendom &&
    formaterAdresseForEiendom(
      eiendom.adresser,
      eiendom.matrikkelnummer,
      eiendom.matrikkelenhetstype,
    )

  const KommuneOverskrift = () =>
    eiendom ? (
      <Brødtekst>{t("kommune", { ns: "nøkkelbegreper", navn: eiendom.kommunenavn })}</Brødtekst>
    ) : (
      <Skeleton
        mt="2px"
        h={{
          base: "20px",
          md: "28px",
        }}
        w="120px"
      />
    )

  const AdresseOverskrift = () =>
    adresse ? (
      <Overskrift variant="h1" noOfLines={3}>
        {adresse}
      </Overskrift>
    ) : (
      <Skeleton
        mt="2px"
        h={{
          base: "30px",
          md: "36px",
        }}
        w="240px"
      />
    )

  const SectionDivider = ({ marginBottom = "12px", marginTop = "12px" }) => {
    return <Divider mt={marginTop} mb={marginBottom} />
  }

  const hentBygningstyper = ({ bygninger }: { bygninger: Bygning[] | undefined }) => {
    const sortertEtterKodeliste = (bygninger: Bygning[]): Bygning[] => {
      return [...bygninger].sort((a, b) => {
        const bygningstypeA = parseInt(a.bygningstype)
        const bygningstypeB = parseInt(b.bygningstype)
        return bygningstypeA - bygningstypeB
      })
    }

    const reduserTilUnikeBygningstyper = (bygninger: Bygning[]): string[] => {
      const unikeBygningstyper = new Set<string>()
      bygninger.forEach(bygning => {
        unikeBygningstyper.add(hentBygningstype(bygning.bygningstype))
      })
      return Array.from(unikeBygningstyper)
    }

    const bygningerSortert = bygninger && sortertEtterKodeliste(bygninger)
    return bygningerSortert && reduserTilUnikeBygningstyper(bygningerSortert)
  }

  return (
    <Stack>
      <Box overflow="hidden" pos="relative">
        <KartutsnittForDetaljertEiendom eiendom={eiendom} />
      </Box>
      <Stack mt="20px">
        <KommuneOverskrift />
        <AdresseOverskrift />
      </Stack>
      <SectionDivider />
      <Overskrift color="gray.600" variant="h4" mb={marginBottomForOverskrift}>
        {t("nøkkelinfo_tittel", { ns: "detaljertInnsyn" })}
      </Overskrift>
      <NøkkelinfoOmEiendommen eiendom={eiendom} />
      <SectionDivider />
      <Overskrift color="gray.600" variant="h4" mb={marginBottomForOverskrift}>
        {t("areal_tittel", { ns: "detaljertInnsyn" })}
      </Overskrift>
      <TeigOgArealInfo eiendom={eiendom} />
      <SectionDivider />

      {eiendom && !erBortfestet(eiendom) && eiendom.bygningerPåEiendom && (
        <>
          <Overskrift color="gray.600" variant="h4" mb={marginBottomForOverskrift}>
            {t("bygning_tittel", { ns: "detaljertInnsyn" })}
          </Overskrift>
          <BygningstyperDetalj
            unikeBygningstyper={hentBygningstyper({ bygninger: eiendom.bygningerPåEiendom })}
          />
        </>
      )}

      {eiendom && !erBortfestet(eiendom) && eiendom.festegrunner && eiendom.festegrunner.length && (
        <>
          <SectionDivider />
          <Overskrift color="gray.600" variant="h4" mb={marginBottomForOverskrift}>
            {t("festegrunner_tittel", { ns: "detaljertInnsyn" })}
          </Overskrift>
          <FestegrunnerPåEiendomAccordion festegrunner={eiendom.festegrunner} />
        </>
      )}
    </Stack>
  )
}

export default Eiendomsdetaljer
