import { CheckboxGroup, FormLabel, Grid, Stack } from "@kvib/react"
import { UseFormRegister } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "../types/schema"
import CheckboxForEgenregistrering from "./CheckboxForEgenregistrering"

interface Props<T extends Record<string, string>> {
  name: keyof EgenregistreringSchema
  register: UseFormRegister<EgenregistreringSchema>
  options: T
  felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }
}

const Flervalg = ({ name, register, options, felt }: Props<Record<string, string>>) => {
  const { t } = useTranslation("egenregistrering")

  const optionMedEksempeltekst = [
    "Biobrensel",
    "AnnenOppvarming",
    "Sentralvarme",
    "Elektrisk",
    "OljeParafin",
  ]

  const hentOptionMedEksempeltekst = (label: string, key: string) => {
    if (!optionMedEksempeltekst.includes(key)) return label
    const hjelpetekst = t(`hjelpetekst_flervalg.${key}` as never, {
      ns: "egenregistrering",
    })
    return label + hjelpetekst
  }

  return (
    <Stack>
      <FormLabel>Velg {felt.key} for bygningen</FormLabel>
      <CheckboxGroup>
        <Grid ml="12px" gap={2}>
          {Object.entries(options).map(([key, label]) => (
            <CheckboxForEgenregistrering
              key={key}
              label={hentOptionMedEksempeltekst(label, key)}
              register={register}
              name={name}
              feltKey={key}
            />
          ))}
        </Grid>
      </CheckboxGroup>
    </Stack>
  )
}

export default Flervalg
