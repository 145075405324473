import { z } from "zod"
import {
  AvløpKeysEnum,
  EnergikildeKeysEnum,
  OppvarmingKeysEnum,
  VannforsyningKeysEnum,
} from "./Enums"

export type IkonForFelt =
  | "calendar_today"
  | "square_foot"
  | "bolt"
  | "heat"
  | "water_drop"
  | "valve"

export type Felter = keyof EgenregistreringSchema

export type FelterLabels =
  | "Byggeår"
  | "Totalt bruksareal"
  | "Energikilder"
  | "Vannforsyning"
  | "Oppvarming"
  | "Avløp"
  | ""

const preprocessOptionalToUndefined = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess(input => {
    if (
      input === "" ||
      Number.isNaN(input) ||
      input === false ||
      input === null ||
      (Array.isArray(input) && input.length === 0)
    ) {
      return undefined
    }
    return input
  }, schema)

const validateByggeår = z
  .number({
    required_error: "Vennligst oppgi et gyldig byggeår",
  })
  .nonnegative("Byggeår må være et gyldig årstall")
  .min(1000, "Byggeår må være et gyldig årstall")
  .max(new Date().getFullYear(), "Byggeår kan ikke være frem i tid")

const validateBruksareal = z
  .number({
    required_error: "Vennligst oppgi et gyldig bruksareal for bygningen",
  })
  .nonnegative("Bruksareal kan ikke være negativt")
  .min(1, { message: "Bruksareal må være større enn 0" })

export const egenregistreringSchema = z
  .object({
    byggeår: preprocessOptionalToUndefined(validateByggeår),
    bruksareal: preprocessOptionalToUndefined(validateBruksareal),
    oppvarming: preprocessOptionalToUndefined(
      z
        .enum(OppvarmingKeysEnum, {
          required_error: "Vennligst oppgi minst én oppvarmingstype",
        })
        .array(),
    ),
    energikilder: preprocessOptionalToUndefined(
      z
        .enum(EnergikildeKeysEnum, {
          required_error: "Vennligst oppgi minst én energkilde",
        })
        .array(),
    ),
    vannforsyning: preprocessOptionalToUndefined(
      z
        .enum(VannforsyningKeysEnum, {
          required_error: "Vennligst oppgi bygningens vannforsyning",
        })
        .nullable(),
    ),
    avløp: preprocessOptionalToUndefined(
      z
        .enum(AvløpKeysEnum, {
          required_error: "Vennligst oppgi bygningens avløp",
        })
        .nullable(),
    ),
  })
  .partial()

export type EgenregistreringSchema = z.infer<typeof egenregistreringSchema>
