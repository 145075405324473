import posthog from "posthog-js"

export interface Consent {
  isVisible: boolean
  consentGiven: boolean
}

export type Action =
  | { type: "GIVE_CONSENT" }
  | { type: "DECLINE_CONSENT" }
  | { type: "SHOW_BANNER" }

export const initialConsent = (): Consent => {
  const consent = localStorage.getItem("cookieConsent")
  return {
    isVisible: consent === null,
    consentGiven: consent === "true",
  }
}

export const reducer = (state: Consent, action: Action): Consent => {
  switch (action.type) {
    case "GIVE_CONSENT":
      posthog.opt_in_capturing()
      // TODO: Fjern kommentar når DPIA er OK
      //posthog.startSessionRecording()

      localStorage.setItem("cookieConsent", "true")
      return { isVisible: false, consentGiven: true }
    case "DECLINE_CONSENT":
      posthog.opt_out_capturing()
      // TODO: Fjern kommentar når DPIA er OK
      //posthog.stopSessionRecording()

      localStorage.setItem("cookieConsent", "false")
      return { ...state, isVisible: false, consentGiven: false }
    case "SHOW_BANNER":
      return { ...state, isVisible: true }
    default:
      return state
  }
}
