import { Bruksenhet } from "../../types/DetaljertEiendom"
import { EtasjeResponse } from "../types/Bygning"

export function formaterEtasjetekst(bruksenhet: Bruksenhet): string {
  if (bruksenhet.etasjenummer === "0.0") {
    return "Ikke registrert"
  }

  const bruksenhetsBokstav = bruksenhet.bruksenhetsnummer.charAt(0)

  const etasjeplankodeverdi = transformMapping(bruksenhetsBokstav)
  const nummer = parseInt(bruksenhet.bruksenhetsnummer.slice(1, 3), 10) // Extract the number part and convert to integer

  if (etasjeplankodeverdi === "Kjeller" || etasjeplankodeverdi === "Loft") {
    return etasjeplankodeverdi
  }

  if (etasjeplankodeverdi === "hovedetasje" || etasjeplankodeverdi === "underetasje") {
    return `${nummer}. ${etasjeplankodeverdi}`
  }

  return "Ikke registrert"
}

const transformMapping = (bruksenhetBokstav: string): string | undefined => {
  const formaterteEtasjeverdierMapping: Mapping = {
    hovedetasje: "hovedetasje",
    underetasje: "underetasje",
    kjelleretasje: "Kjeller",
    loftsetasje: "Loft",
    "Ikke registrert": "Ikke registrert",
  }

  const etasjeplankodeVerdi =
    etasjeplankodeMapping[bruksenhetBokstav as keyof typeof etasjeplankodeMapping] ||
    "Ikke registrert"
  return formaterteEtasjeverdierMapping[etasjeplankodeVerdi]
}

export const sorterEtterPlankodeDeretterEtasjeummer = (
  etasjer: EtasjeResponse[],
): EtasjeResponse[] => {
  const rangering: { [key in "H" | "U" | "L" | "K" | " "]: number } = {
    L: 4,
    H: 3,
    U: 2,
    K: 1,
    " ": 0,
  }

  return etasjer.sort((a, b) => {
    const rankDiff = (rangering[b.etasjeplankode] || 0) - (rangering[a.etasjeplankode] || 0)
    if (rankDiff !== 0) {
      return rankDiff
    }

    return b.etasjenummer.localeCompare(a.etasjenummer, undefined, { numeric: true })
  })
}

type Mapping = {
  [key: string]: string | undefined
}

export const etasjeplankodeMapping = {
  H: "hovedetasje",
  U: "underetasje",
  K: "kjelleretasje",
  L: "loftsetasje",
  " ": "Ikke registrert",
} as const
