import { Box, Flex } from "@kvib/react"
import DetaljPopover from "../../components/DetaljPopover"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const BortfestetDetalj = () => {
  return (
    <DetaljPopover
      forklaring="At eiendommen er festet bort betyr at noen andre har en aktiv festekontrakt (leier). Derfor er det begrenset hvilke opplysninger du som eier får se. "
      buttonAriaLabel=""
    >
      <OverskriftForEiendomsdetaljer>Er festet bort</OverskriftForEiendomsdetaljer>

      <Box as={Flex} alignItems="center">
        <FeltverdiForEiendomsdetaljer fieldValue="Ja" />
      </Box>
    </DetaljPopover>
  )
}

export default BortfestetDetalj
