import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import useEgenregistrering from "../api/useEgenregistrering"
import { Felter } from "../types/schema"

export const useMetadata = (feltKey?: Felter) => {
  const { data: egenregistreringForBygning, isSuccess, isPending } = useEgenregistrering()
  const { dineBruksenheterIBygning } = useCurrentBygning()

  if (
    !egenregistreringForBygning ||
    !isSuccess ||
    isPending ||
    !dineBruksenheterIBygning ||
    !feltKey
  )
    return undefined

  const bruksenhetForEgenregistrering = egenregistreringForBygning.bruksenheter.find(enhet =>
    dineBruksenheterIBygning.some(b => b.bruksenhetsId === enhet.bruksenhetId.toString()),
  )

  if (!bruksenhetForEgenregistrering) return undefined

  const getMetadataFromArray = (
    arr?: { metadata?: { registreringstidspunkt: string; registrertAvDeg: boolean } }[],
  ) => {
    return arr && arr.length > 0 ? arr[0].metadata : undefined
  }

  switch (feltKey) {
    case "byggeår":
      return bruksenhetForEgenregistrering.byggeår?.metadata
    case "bruksareal":
      return bruksenhetForEgenregistrering.bruksareal?.metadata
    case "energikilder":
      return getMetadataFromArray(bruksenhetForEgenregistrering.energikilder)
    case "oppvarming":
      return getMetadataFromArray(bruksenhetForEgenregistrering.oppvarming)
    case "vannforsyning":
      return bruksenhetForEgenregistrering.vannforsyning?.metadata
    case "avløp":
      return bruksenhetForEgenregistrering.avløp?.metadata
    default:
      return undefined
  }
}
