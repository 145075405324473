import { SimpleGrid } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useScreenView } from "../../../core/hooks/useScreenview"
import { BortfestetDetaljertEiendom, EidDetaljertEiendom } from "../types/DetaljertEiendom"
import BortfestetDetalj from "./BortfestetDetalj"
import Eierandeldetalj from "./Eierandeldetalj"
import Matrikkelenhetstypedetalj from "./Matrikkelenhetstypedetalj"
import Matrikkelnummerdetalj from "./Matrikkelnummerdetalj"
import { erBortfestet } from "../api/eiendom"

const NøkkelinfoOmEiendommen = ({
  eiendom,
}: {
  eiendom: EidDetaljertEiendom | BortfestetDetaljertEiendom | undefined
}) => {
  const { t } = useTranslation("nøkkelbegreper")
  const { isDesktop } = useScreenView()

  return (
    <SimpleGrid gap={4} gridTemplateColumns={isDesktop ? "1fr 1fr 1fr 1fr" : "1fr"}>
      <Matrikkelnummerdetalj key="matrikkelnummer" matrikkelnummer={eiendom?.matrikkelnummer} />
      <Matrikkelenhetstypedetalj
        key="matrikkelenhetstype"
        matrikkelenhetstype={eiendom?.matrikkelenhetstype}
      />
      {eiendom && erBortfestet(eiendom) && <BortfestetDetalj />}
      <Eierandeldetalj
        key="eierandel"
        eierandel={
          eiendom?.eierforhold.eierandel === null
            ? t("ikke_registrert")
            : eiendom?.eierforhold.eierandel
        }
      />
    </SimpleGrid>
  )
}

export default NøkkelinfoOmEiendommen
