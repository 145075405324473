import { Flex } from "@kvib/react"
import { UseFormRegister } from "react-hook-form"
import { EgenregistreringSchema } from "../types/schema"

interface RadioProps {
  feltKey: string
  label: string
  name: keyof EgenregistreringSchema
  register: UseFormRegister<EgenregistreringSchema>
}
const Radio = (props: RadioProps) => {
  const { feltKey, label, name, register } = props
  return (
    <Flex>
      <input
        style={{ width: "16px", accentColor: "green", marginRight: "4px" }}
        id={feltKey}
        type="radio"
        key={feltKey}
        value={feltKey}
        {...register(name)}
      />
      <label htmlFor={feltKey}>{label}</label>
    </Flex>
  )
}

export default Radio
