import { useMemo } from "react"
import { useParams } from "react-router-dom"
import useCurrentEiendom from "./useCurrentEiendom"
import { erBortfestet } from "../api/eiendom"

const useCurrentBygning = (currentBygningId?: string) => {
  const { bygningId: paramBygningId } = useParams<{ bygningId: string }>()
  const { eiendom } = useCurrentEiendom()

  const bygningId = currentBygningId ?? paramBygningId
  const bygningIdNumber = useMemo(() => Number(bygningId), [bygningId])

  const currentBygning = useMemo(() => {
    if (!eiendom || erBortfestet(eiendom)) return undefined
    return eiendom?.bygningerPåEiendom.find(bygning => Number(bygning.byggId) === bygningIdNumber)
  }, [eiendom, bygningIdNumber])

  const dineBruksenheterIBygning = useMemo(() => {
    if (!eiendom || erBortfestet(eiendom)) return undefined
    return (
      eiendom?.bruksenheterPåEiendom.filter(
        bruksenhet => Number(bruksenhet.byggId) === bygningIdNumber,
      ) ?? []
    )
  }, [eiendom, bygningIdNumber])

  return { currentBygning, dineBruksenheterIBygning }
}

export default useCurrentBygning
