const keys = {
  "nb-NO": {
    egenregistrering_sidetittel: "Egenregistrerte opplysninger",
    hjelpetekst_avløp:
      "Avløpskoden beskriver systemet for håndtering av brukt vann fra bygningen. Det gjelder både hvordan vann fra vask og dusj, og vann fra toalett, blir håndtert.",
    hjelpetekst_bruksareal:
      "Du må registrere det totale bruksarealet for hver etasje i bygningen. Bruksareal inkluderer alt areal innenfor ytterveggene. Dersom du har innglasset balkong inngår dette i bruksarealet.",
    hjelpetekst_byggeår:
      "Byggeåret er det året bygningen var ferdigstilt og kunne tas i bruk første gang. Rehabilitering, oppussing og påbygning i ettertid endrer ikke byggeåret.",
    hjelpetekst_energikilder:
      "Dette er den eller de kildene som gir energi til å varme opp bygningen.",
    hjelpetekst_oppvarmingstyper:
      "Dette er hovedmåten varme leveres på i bygningen. Velg den eller de som gir mest varme i bygningen hele året.",
    hjelpetekst_flervalg: {
      AnnenOppvarming: " (f.eks. peis, kamin, ovn)",
      Sentralvarme: " (f.eks. varme fra fjernvarmeanlegg, vannbåren varme)",
      Elektrisk: " (f.eks. panelovn, gulvvarme, luft-luft varmepumpe)",
      OljeParafin: " (f.eks. olje, parafin)",
      Biobrensel: " (f.eks. ved, pellets)",
    },
    omtvistet:
      "En eller flere av grensene til teigen er omtvistet, eller teigen er registrert som omtvistet. Arealet vil være usikkert inntil den eller de uavklarte grensene er avklart.",
    noen_fiktive_hjelpelinjer:
      "En eller flere (men ikke alle) av grensene til teigen er av typen fiktiv hjelpelinje. Arealet som beregnes vil derfor være usikkert.",
    hjelpelinje_vannkant:
      "En eller flere av grensene til teigen er av type vannkant. Arealet som beregnes vil derfor være usikkert.",
    hjelpelinje_vegkant:
      "En eller flere av grensene til teigen er av type vegkant. Arealet som beregnes vil derfor være usikkert.",
    har_punktfester:
      "En eller flere (men ikke alle) av grensene til teigen er av type punktfeste fordi det er registrert punktfester på eiendommen. Arealet til punktfestene beregnes inn i arealet til eiendommen og eiendommens areal vil derfor være usikkert.",
    ukjent_arealmerknad: "Ukjent arealmerknad. ",
    hjelpetekst_vannforsyning:
      "Vannforsyning beskriver om og hvordan bygningen får tilgang til rent vann. Dersom du henter vann fra bekk eller brønn uten tilkobling til bygningen benyttes 'annen privat men ikke innlagt vann'.",
    tekst_registrer_verdi_knapp: "registrer",
    tekst_endre_verdi_knapp: "endre",
    tekst_fjern_verdi_knapp: "fjern",
    eierseksjon_registrering_hjelpetekst_i_infoboks:
      " Du eier en seksjon som er en del av en større bygning. Du skal kun registrere på det du eier i bygningen (din bruksenhet).",
  },
  "nn-NO": {
    hjelpetekst_avløp: "",
    hjelpetekst_bruksareal: "",
    hjelpetekst_byggeår: "",
    hjelpetekst_energikilder: "",
    hjelpetekst_oppvarmingstyper: "",
  },
} as const

export const egenregistrering = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
