import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Flex,
} from "@kvib/react"
import { useScreenView } from "../../../../core/hooks/useScreenview"
import { GåTilEgenregistreringKnapp } from "../../../registrering/components/GåTilEgenregistreringKnapp"
import useCurrentBygning from "../../hooks/useCurrentBygning"
import useCurrentEiendom from "../../hooks/useCurrentEiendom"
import { getVegadresserPåBygning } from "../utils/bygningerUtils"
import { BygningskortInnhold } from "./BygningskortInnhold"
import BygningstypeOgVegadresseOverskrift from "./BygningstypeOgAdresseOverskrift"

interface Props extends CardProps {
  bygningId: string
}

const Bygningskort = ({ bygningId, children, ...props }: Props) => {
  const { isMobile } = useScreenView()
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning(bygningId)
  const { eiendom } = useCurrentEiendom()

  if (!currentBygning || !dineBruksenheterIBygning || !eiendom || !eiendom.adresser.vegadresser)
    return null

  const vegadresserPåBygning = getVegadresserPåBygning(
    eiendom.adresser.vegadresser,
    dineBruksenheterIBygning.map(enhet => enhet.bruksenhetsId),
  )

  const BygningskortAccordionForMobil = () => (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem p={0} as={Card} border="none" {...props}>
        <h3>
          <AccordionButton
            minH="120px"
            _hover={{
              bg: "inherit",
              borderRadius: "inherit",
            }}
            _expanded={{
              borderRadius: "inherit",
            }}
          >
            <BygningstypeOgVegadresseOverskrift
              erSeksjon={eiendom.matrikkelenhetstype === "Eierseksjon"}
              bygningstype={currentBygning.bygningstype}
              vegadresse={vegadresserPåBygning}
            />
            <AccordionIcon />
          </AccordionButton>
        </h3>
        <AccordionPanel mb="16px" p={0}>
          {children}
          <Flex m="16px">
            <GåTilEgenregistreringKnapp bygningId={bygningId} />
          </Flex>
          <BygningskortInnhold bygningsId={bygningId} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )

  return isMobile ? (
    <BygningskortAccordionForMobil />
  ) : (
    <Card {...props}>
      <CardHeader as={Flex}>
        <BygningstypeOgVegadresseOverskrift
          erSeksjon={eiendom.matrikkelenhetstype === "Eierseksjon"}
          bygningstype={currentBygning.bygningstype}
          vegadresse={vegadresserPåBygning}
        />

        <Flex m="16px">
          <GåTilEgenregistreringKnapp bygningId={bygningId} />
        </Flex>
      </CardHeader>
      <CardBody pt="0">
        <BygningskortInnhold bygningsId={bygningId} />
      </CardBody>
    </Card>
  )
}

export default Bygningskort
