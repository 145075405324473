import { Box, Flex, SimpleGrid } from "@kvib/react"
import { useTranslation } from "react-i18next"
import PopoverikonMedTooltip from "../../../../core/components/PopoverikonMedTooltip"
import ErrorHandler from "../../../../core/error-handling/ErrorHandler"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import DetaljPopover from "../../../components/DetaljPopover"
import { useKodelister } from "../../../kodelister/useKodelister"
import { hentUtfraKodeliste } from "../../../kodelister/utils"
import { Bruksenhet, EidDetaljertEiendom } from "../../types/DetaljertEiendom"
import { formaterBruksareal } from "../utils/bygningerUtils"
import { formaterEtasjetekst } from "../utils/etasjeUtil"

interface Props {
  eiendom: EidDetaljertEiendom
  byggId: string
}

const NøkkelinfoOmAlleBruksenheterIBygning = ({ eiendom, byggId }: Props) => {
  const bruksenheterIbygning = eiendom.bruksenheterPåEiendom.filter(
    bruksenhet => bruksenhet.byggId === byggId,
  )

  const { t } = useTranslation(["bruksenhetskort", "nøkkelbegreper"])
  const { data: kodelister, isError, isLoading, isSuccess, error } = useKodelister()

  if (isError) return <ErrorHandler apiError={error} />

  if (isLoading || !isSuccess) return null

  const sortertSåBruksenheterIHovedetasjeKommerFørst = (a: Bruksenhet, b: Bruksenhet) => {
    const aIsH = a.bruksenhetsnummer.startsWith("H") ? 0 : 1
    const bIsH = b.bruksenhetsnummer.startsWith("H") ? 0 : 1
    return aIsH - bIsH
  }

  const hjelpetekstBruksenhetsnummer = t("bruksenhetsnummer_forklaring", { ns: "bruksenhetskort" })
  const unummerertBruksenhetForklaring = t("unummerertBruksenhet_forklaring", {
    ns: "bruksenhetskort",
  })
  const helpetekstBruksenhetstype = t("bruksenhetstype_forklaring", { ns: "bruksenhetskort" })
  const bruksarealForEnBruksenhetForklaring = t("bruksareal_for_en_bruksenhet_forklaring", {
    ns: "bruksenhetskort",
  })
  const etasjeForInngangHjelpetekst = t("inngang_etasje_forklaring", { ns: "bruksenhetskort" })

  return (
    <>
      {bruksenheterIbygning.sort(sortertSåBruksenheterIHovedetasjeKommerFørst).map(bruksenhet => (
        <Box
          key={bruksenhet.bruksenhetsnummer}
          bg="green.50"
          mt="8px"
          p="16px"
          borderRadius="8px"
          aria-label={t("bruksenhetskort_arialabel", {
            ns: "bruksenhetskort",
            bruksenhetsnummer: bruksenhet.bruksenhetsnummer,
          })}
        >
          <SimpleGrid
            autoRows="1fr 1fr"
            templateColumns={{
              base: "1fr",
              sm: "1fr 1fr",
            }}
            gap={3}
          >
            <DetaljPopover
              forklaring={helpetekstBruksenhetstype}
              buttonAriaLabel={t("hva_betyr_type_arialabel", {
                ns: "nøkkelbegreper",
                type: "bruksenhetstype",
              })}
            >
              <Brødtekst color="gray.600" litenVariant>
                {t("bruksenhetstype_overskrift", { ns: "bruksenhetskort" })}
              </Brødtekst>
              <Brødtekst fontWeight="bold" as={Flex} alignItems="center">
                {hentUtfraKodeliste(bruksenhet.bruksenhetstype, kodelister.bruksenhettypeKodeliste)}
                {bruksenhet.erUnummerert && (
                  <PopoverikonMedTooltip
                    tooltipLabel={t("hva_betyr_type_arialabel", {
                      ns: "nøkkelbegreper",
                      type: "unummerert bruksenhet",
                    })}
                    tooltipAriaLabel={t("hva_betyr_dette", { ns: "nøkkelbegreper" })}
                    iconButtonAriaLabel={t("hva_betyr_type_arialabel", {
                      ns: "nøkkelbegreper",
                      type: "unummerert bruksenhet",
                    })}
                    icon="help"
                  >
                    <>{unummerertBruksenhetForklaring}</>
                  </PopoverikonMedTooltip>
                )}
              </Brødtekst>
            </DetaljPopover>
            <DetaljPopover
              forklaring={hjelpetekstBruksenhetsnummer}
              buttonAriaLabel={t("hva_betyr_type_arialabel", {
                ns: "nøkkelbegreper",
                type: "bruksenhetsnummer",
              })}
            >
              <Brødtekst color="gray.600" litenVariant>
                {t("bruksenhetsnummer_overskrift", { ns: "bruksenhetskort" })}
              </Brødtekst>
              <Brødtekst fontWeight="bold">
                {bruksenhet.erUnummerert ? "-" : bruksenhet.bruksenhetsnummer}
              </Brødtekst>
            </DetaljPopover>
            <DetaljPopover
              forklaring={etasjeForInngangHjelpetekst}
              buttonAriaLabel={t("hva_betyr_type_arialabel", {
                ns: "nøkkelbegreper",
                type: "inngang",
              })}
            >
              <Brødtekst color="gray.600" litenVariant>
                {t("inngang_etasje_overskrift", { ns: "bruksenhetskort" })}
              </Brødtekst>
              <Brødtekst fontWeight="bold">{formaterEtasjetekst(bruksenhet)}</Brødtekst>
            </DetaljPopover>
            <DetaljPopover
              forklaring={bruksarealForEnBruksenhetForklaring}
              buttonAriaLabel={t("hva_betyr_type_arialabel", {
                ns: "nøkkelbegreper",
                type: "bruksareal",
              })}
            >
              <Brødtekst color="gray.600" litenVariant>
                {t("bruksareal_for_en_bruksenhet_overskrift", { ns: "bruksenhetskort" })}
              </Brødtekst>
              <Brødtekst fontWeight="bold">{formaterBruksareal(bruksenhet.bruksareal)}</Brødtekst>
            </DetaljPopover>
          </SimpleGrid>
        </Box>
      ))}
    </>
  )
}

export default NøkkelinfoOmAlleBruksenheterIBygning
