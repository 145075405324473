import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton, Flex } from "@kvib/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export const Driftsmelding = () => {
  const { t } = useTranslation("errormeldinger")
  const [isOpen, setIsOpen] = useState<boolean>(visDriftsmelding())

  return isOpen ? (
    <Alert status="warning">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex" alignItems="center">
          <AlertIcon />
          <Box>
            <AlertTitle>{t("nedetid_tittel")}</AlertTitle>
            <AlertDescription>{t("nedetid_beskrivelse")}</AlertDescription>
          </Box>
        </Box>
        <CloseButton onClick={() => setIsOpen(false)} />
      </Flex>
    </Alert>
  ) : null
}

const nedetid = ["2024-10-31", "2024-11-28", "2025-01-30"]

const visDriftsmelding = () => {
  const today = new Date().toISOString().slice(0, 10)
  return nedetid.includes(today)
}
