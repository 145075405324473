import useEgenregistrering from "./api/useEgenregistrering"
import EgenregistrerteOpplysninger from "./EgenregistrerteOpplysninger"

const Egenregistrering = () => {
  const { data: egenregistreringForBygning, isSuccess, isPending } = useEgenregistrering()

  if (isPending || !isSuccess) {
    return null
  }

  return <EgenregistrerteOpplysninger egenregistreringForBygning={egenregistreringForBygning} />
}

export default Egenregistrering
