import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Vannforsyningstype, enumToOptions } from "../types/Enums"
import { getModalLabel } from "../utils"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import { InvalidInputAlert } from "./InvalidInputAlert"
import VannforsyningOgAvløpRadioGroup from "./VannforsyningOgAvløpRadioGroup"

const RegistrerVannforsyning = ({ feltnavn }: { feltnavn: "vannforsyning" }) => {
  const { t } = useTranslation("egenregistrering")
  const formContext = useFormContext()

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_vannforsyning")}</HjelpetekstForFelt>
      <VannforsyningOgAvløpRadioGroup
        register={formContext.register}
        name={feltnavn}
        options={enumToOptions(Vannforsyningstype) as typeof Vannforsyningstype}
        felt={{
          key: feltnavn,
          label: getModalLabel(feltnavn),
          icon: "water_drop",
        }}
      />
      <InvalidInputAlert felt={feltnavn} />
    </>
  )
}

export default RegistrerVannforsyning
