import { Box, Button, Center, Flex, Heading } from "@kvib/react"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import Section from "../core/components/layout/Section"
import StackMedGap from "../core/components/layout/StackMedGap"
import { useScreenView } from "../core/hooks/useScreenview"
import { Brødtekst } from "../core/text/components/Brødtekst"
import { Overskrift } from "../core/text/components/Overskrift"
import { InfoCard } from "../infosider/components/InfoCard"
import CookieTabell from "./components/CookiesTabell"
import { ConsentContext } from "./context/ConsentContext"

const Cookieserklæring = () => {
  const { t } = useTranslation("cookies", { lng: "nn-NO" })
  const { isMobile } = useScreenView()

  const { dispatch } = useContext(ConsentContext)
  return (
    <Section pb={["20px", "160px"]}>
      <Center>
        <StackMedGap>
          <Overskrift variant="h1">{t("tittel")}</Overskrift>

          <InfoCard cardHeader={t("informasjonskapsler.tittel")}>
            <Box>
              {t("informasjonskapsler", { returnObjects: true }).avsnitt.map(tekst => (
                <Box key={tekst}>
                  <Brødtekst>{tekst}</Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>
            <Heading size="sm">{t("nødvendige_informasjonskapsler.tittel")}</Heading>
            <Box>
              {t("nødvendige_informasjonskapsler", { returnObjects: true }).avsnitt.map(tekst => (
                <Box key={tekst}>
                  <Brødtekst>{tekst}</Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>

            <Heading size="sm">{t("webanalyse.tittel")}</Heading>
            <Box>
              {t("webanalyse", { returnObjects: true }).avsnitt.map(tekst => (
                <Box key={tekst}>
                  <Brødtekst>{tekst}</Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>
            <Heading size="sm">{t("administrer_cookies.tittel")}</Heading>
            <Box>
              {t("administrer_cookies.avsnitt", { returnObjects: true }).map(tekst => (
                <Box key={tekst}>
                  <Brødtekst>{tekst}</Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>
            <Flex alignItems="end" justifyContent="flex-start">
              <Button
                id="administer-cookies"
                variant="secondary"
                onClick={() => dispatch({ type: "SHOW_BANNER" })}
              >
                {t("administrer_cookies.tittel")}
              </Button>
            </Flex>
          </InfoCard>

          {!isMobile && (
            <InfoCard cardHeader={t("oversikt_over_cookies_på_mineiendom")}>
              <CookieTabell />
            </InfoCard>
          )}

          <InfoCard cardHeader={t("cookiesforklaring_tittel")}>
            <Box>
              {t("cookiesforklaring", { returnObjects: true }).map(tekst => (
                <Box key={tekst.tittel}>
                  <Heading size="sm">{tekst.tittel}</Heading>
                  <Brødtekst>{tekst.avsnitt}</Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>
          </InfoCard>
        </StackMedGap>
      </Center>
    </Section>
  )
}

export default Cookieserklæring
