import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import useEgenregistrering from "../api/useEgenregistrering"
import { Felter } from "../types/schema"
import { ikkeRegistrertVerdi, visEgenregistrertVerdi } from "../utils"

const useErTomVerdi = () => {
  const { data: egenregistreringForBygning } = useEgenregistrering()
  const { dineBruksenheterIBygning } = useCurrentBygning()

  if (!dineBruksenheterIBygning) return () => true

  return (felt: Felter) => {
    if (!egenregistreringForBygning) {
      return true
    }

    const fieldValue = visEgenregistrertVerdi(
      felt,
      egenregistreringForBygning,
      dineBruksenheterIBygning[0].bruksenhetsId,
    )

    return fieldValue === ikkeRegistrertVerdi || !fieldValue
  }
}

export default useErTomVerdi
