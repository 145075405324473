import { Button, GridProps, Skeleton, Stack } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useScreenView } from "../../../core/hooks/useScreenview"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { useFontStyles } from "../../../core/text/fontStyles"

import useTeigpolygoner from "../../../interaktivtKart/api/useTeigpolygoner"
import FellesKartutsnittkomponent from "../../../kartutsnitt/components/FellesKartutsnittkomponent"
import { MatrikkelnummerInfoPopover } from "../../components/MatrikkelnummerInfoPopover"
import MatrikkeltypePopover from "../../components/MatrikkeltypePopover"
import { Eiendom } from "../types/Eiendom"
import { formaterAdresseForEiendom } from "../utils/adresseForEiendomUtils"
import { formaterMatrikkelnummer } from "../utils/eiendomUtils"
import EiendomskortContainer from "./EiendomskortContainer"

interface Props extends GridProps {
  eiendom: Eiendom
  index: number
}

const Eiendomskort = ({ eiendom, index, ...props }: Props) => {
  const { isMobile, isDesktop } = useScreenView()
  const { t } = useTranslation(["nøkkelbegreper", "eiendomskort"])

  const { data: teig, isPending, isSuccess } = useTeigpolygoner(eiendom.matrikkelnummer)

  const adresse = formaterAdresseForEiendom(
    eiendom.adresser,
    eiendom.matrikkelnummer,
    eiendom.matrikkelenhetstype,
  )

  const navigate = useNavigate()
  const åpneDetaljer = () => {
    navigate(`/eiendom/${eiendom.eiendomsIndex}`)
  }

  const { getFontSize } = useFontStyles()

  return (
    <EiendomskortContainer
      h="100%"
      w="100%"
      onClick={åpneDetaljer}
      onKeyDown={e => (e.key === "Enter" && åpneDetaljer()) || (e.key === " " && åpneDetaljer())}
      aria-label={t("eiendomskort_arialabel", {
        ns: "eiendomskort",
        index: index + 1,
        adresse: adresse,
      }).toString()}
      {...props}
    >
      {isPending ? (
        <Skeleton />
      ) : (
        isSuccess && <FellesKartutsnittkomponent metadata={{ eiendom: eiendom }} teig={teig} />
      )}
      <Stack>
        <Brødtekst
          color="gray.600"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          fontSize={isMobile ? "xs" : "sm"}
          fontWeight="bold"
        >
          {t("kommune", { ns: "nøkkelbegreper", navn: eiendom.kommunenavn })}
        </Brødtekst>
        <Overskrift
          variant="h2"
          fontSize={getFontSize("h3")}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            wordBreak: "break-word",
            textOverflow: "ellipsis",
            minHeight: "3em",
          }}
        >
          {adresse}
        </Overskrift>
      </Stack>
      {isDesktop ? (
        <Stack>
          <MatrikkelnummerInfoPopover
            matrikkelnummer={formaterMatrikkelnummer(eiendom.matrikkelnummer)}
          />
          <MatrikkeltypePopover matrikkelenhetstype={eiendom.matrikkelenhetstype} />
        </Stack>
      ) : (
        <Button onClick={åpneDetaljer} variant="tertiary" rightIcon="arrow_forward" p="0">
          Se flere opplysninger
        </Button>
      )}
    </EiendomskortContainer>
  )
}

export default Eiendomskort
