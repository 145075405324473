import { Flex, IconProps } from "@kvib/react"
import { formaterStorForbokstav } from "../../../../utils/formateringUtils"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { Felter, FelterLabels, IkonForFelt } from "../types/schema"
import CircularIcon from "./CircularIcon"
interface Props extends IconProps {
  felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }
  iconSize?: number
}

export const IkonOgFeltLabel = ({ felt, iconSize }: Props) => {
  return (
    <Flex alignItems="center">
      <CircularIcon iconSize={iconSize} icon={felt.icon} />
      <Overskrift variant="h4">{formaterStorForbokstav(felt.label)}</Overskrift>
    </Flex>
  )
}
