import { usePostHog } from "posthog-js/react"
import { useParams } from "react-router-dom"
import Section from "../../../core/components/layout/Section"
import { finnerIngenPathsSomMatcher } from "../../../core/error-handling/types/finnerIngenPathSomPatcher"
import useCurrentEiendom from "../hooks/useCurrentEiendom"
import Bygningsdetaljer from "./Bygningsdetaljer"
import Eiendomsdetaljer from "./Eiendomsdetaljer"

import FlereOpplysninger from "./FlereOpplysninger"
import { erBortfestet } from "../api/eiendom"

const DetaljertEiendom = () => {
  const { eiendom, isEiendommerPending, finnerIkkeEiendom } = useCurrentEiendom()
  const posthog = usePostHog()
  const { eiendomsIndex } = useParams()

  const commonPaddingForSection = ["30px", "40px"]

  if (!isEiendommerPending && finnerIkkeEiendom) {
    throw finnerIngenPathsSomMatcher
  }

  const eiendomErBortfestet = eiendom && erBortfestet(eiendom)

  const harBygningerPåEiendom =
    eiendom &&
    !eiendomErBortfestet &&
    eiendom.bygningerPåEiendom &&
    eiendom.bygningerPåEiendom.length > 0

  if (!eiendom) {
    return <></>
  } else if (eiendomErBortfestet) {
    return (
      <>
        <Section pt="12px" pb={commonPaddingForSection}>
          <Eiendomsdetaljer eiendom={eiendom} />
        </Section>
        <Section pt={commonPaddingForSection} pb={commonPaddingForSection} bg="white">
          <FlereOpplysninger />
        </Section>
      </>
    )
  } else {
    if (!localStorage.getItem(`${eiendomsIndex}`)) {
      posthog.capture("Antall bygninger", {
        antall_bygninger: !eiendomErBortfestet && eiendom?.bygningerPåEiendom.length,
      })
      localStorage.setItem(`${eiendomsIndex}`, "true")
    }

    return (
      <>
        <Section pt="12px" pb={commonPaddingForSection}>
          <Eiendomsdetaljer eiendom={eiendom} />
        </Section>
        {harBygningerPåEiendom && (
          <Section bg="green.50" pt={commonPaddingForSection} pb={commonPaddingForSection}>
            <Bygningsdetaljer bygningerPåEiendom={eiendom.bygningerPåEiendom} />
          </Section>
        )}
        <Section pt={commonPaddingForSection} pb={commonPaddingForSection} bg="white">
          <FlereOpplysninger />
        </Section>
      </>
    )
  }
}

export default DetaljertEiendom
