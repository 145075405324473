import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@kvib/react"
import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import useCurrentEiendom from "../../detaljert-eiendom/hooks/useCurrentEiendom"
import { useKodelister } from "../../kodelister/useKodelister"
import { felterForEgenregistrering, visMatrikkelregistrertVerdi } from "../utils"

export const VisMatrikkelregistrerteVerdier = () => {
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning()
  const { eiendom } = useCurrentEiendom()
  const { data: kodelister } = useKodelister()
  if (!eiendom) return null

  if (!currentBygning || !kodelister || !dineBruksenheterIBygning) return null

  return (
    <TableContainer>
      <Table colorScheme="green">
        <Thead>
          <Tr>
            <Th>Registrert i Eiendomsregisteret</Th>
          </Tr>
        </Thead>
        <Tbody>
          {felterForEgenregistrering.map(felt => (
            <Tr key={felt.key} h="80px" color="gray">
              <Td>
                {visMatrikkelregistrertVerdi(
                  felt.key,
                  currentBygning,
                  dineBruksenheterIBygning,
                  kodelister,
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
