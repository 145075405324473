import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@kvib/react"
import { useFormContext } from "react-hook-form"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "../types/schema"
import { IkonOgFeltLabel } from "./IkonOgFeltLabel"
import RegistrerAvløp from "./RegistrerAvløp"
import RegistrerBruksareal from "./RegistrerBruksareal"
import RegistrerByggeår from "./RegistrerByggeår"
import RegistrerEnergikilder from "./RegistrerEnergikilder"
import RegistrerOppvarming from "./RegistrerOppvarming"
import RegistrerVannforsyning from "./RegistrerVannforsyning"

interface EgenregistreringModalProps {
  felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: EgenregistreringSchema) => void
  isLoading: boolean
}

const EgenregistreringModal = ({
  felt,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: EgenregistreringModalProps) => {
  const formContext = useFormContext<EgenregistreringSchema>()

  const handleSubmit = () => {
    formContext.handleSubmit(onSubmit)()
  }

  const getFieldContent = (field: keyof EgenregistreringSchema) => {
    switch (field) {
      case "byggeår":
        return <RegistrerByggeår feltnavn={field} />
      case "bruksareal":
        return <RegistrerBruksareal feltnavn={field} />
      case "energikilder":
        return <RegistrerEnergikilder feltnavn={field} />
      case "oppvarming":
        return <RegistrerOppvarming feltnavn={field} />
      case "vannforsyning":
        return <RegistrerVannforsyning feltnavn={field} />
      case "avløp":
        return <RegistrerAvløp feltnavn={field} />
      default:
        return null
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        formContext.clearErrors()
        onClose()
      }}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent gap={2}>
        <ModalHeader>
          <IkonOgFeltLabel felt={felt} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{getFieldContent(felt.key)}</ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            colorScheme="blue"
            mr={3}
            onClick={() => {
              formContext.clearErrors()
              onClose()
            }}
          >
            Avbryt
          </Button>
          <Button onClick={handleSubmit} isLoading={isLoading}>
            Send inn
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EgenregistreringModal
