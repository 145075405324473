import { KvibProvider } from "@kvib/react"
import { PostHogProvider } from "posthog-js/react"
import { createRoot } from "react-dom/client"
import App from "./App"
import "./faro-config"
import "./i18next-config"

const root = createRoot(document.getElementById("root") as HTMLDivElement)

root.render(
  <PostHogProvider
    apiKey="phc_U4kQNaQjNbpak0bTq7oNLNMdgdzF8BfssbDUTGDnXV2"
    options={{
      api_host: "https://eu.i.posthog.com",
      autocapture: false,
      opt_out_capturing_by_default: true,
    }}
  >
    <KvibProvider>
      <App />
    </KvibProvider>
  </PostHogProvider>,
)
