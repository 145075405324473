import { formaterMedKommaOgOg } from "../../../utils/formateringUtils"
import { Kodelister } from "../../shared-api/types/ApiResponseTypes"
import { Bygning } from "../detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { avløpMapping } from "../detaljert-eiendom/bygningsdetaljer/utils/avløpsUtil"
import { energikildeMapping } from "../detaljert-eiendom/bygningsdetaljer/utils/energikilderUtil"
import { hentGjeldendeBygningsstatus } from "../detaljert-eiendom/bygningsdetaljer/utils/hentBygningsstatus"
import { vannforsyningkodeMapping } from "../detaljert-eiendom/bygningsdetaljer/utils/vannforsyningUtil"
import { Bruksenhet } from "../detaljert-eiendom/types/DetaljertEiendom"
import { hentUtfraKodeliste } from "../kodelister/utils"
import { EgenregistreringBygning } from "./types/Egenregistrering"
import { Avløpstype, Energikildetype, Oppvarmingstype, Vannforsyningstype } from "./types/Enums"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "./types/schema"

const ikkeRegistrertMatrikkelverdi = "Ikke registrert"

export const visMatrikkelregistrertVerdi = (
  key: Felter,
  bygning: Bygning,
  dineBruksenheterIBygning: Bruksenhet[],
  kodelister: Kodelister,
) => {
  switch (key) {
    case "byggeår": {
      const { år, status } = hentGjeldendeBygningsstatus(
        bygning.bygningsstatusHistorikk,
        kodelister,
      )
      return år ? `${år}: ${status}` : ikkeRegistrertMatrikkelverdi
    }

    case "bruksareal": {
      const totalBruksareal = dineBruksenheterIBygning.reduce((sum, enhet) => {
        const area = parseFloat(enhet.bruksareal)
        return sum + (isNaN(area) ? 0 : area)
      }, 0)

      return totalBruksareal > 0 ? `${totalBruksareal} m²` : ikkeRegistrertMatrikkelverdi
    }

    case "energikilder": {
      const energikilder = bygning.energikilder || []
      return energikilder.length > 0
        ? formaterMedKommaOgOg(
            energikilder.map(energikilde => energikildeMapping[energikilde] || energikilde),
          )
        : ikkeRegistrertMatrikkelverdi
    }

    case "oppvarming": {
      const oppvarmingstyper = bygning.oppvarming || []
      return oppvarmingstyper.length > 0
        ? formaterMedKommaOgOg(
            oppvarmingstyper.map(type =>
              hentUtfraKodeliste(type, kodelister.oppvarmingstypeKodeliste),
            ),
          )
        : ikkeRegistrertMatrikkelverdi
    }

    case "vannforsyning":
      return bygning.vannforsyning
        ? vannforsyningkodeMapping[bygning.vannforsyning] || ikkeRegistrertVerdi
        : ikkeRegistrertMatrikkelverdi

    case "avløp":
      return bygning.avløp
        ? avløpMapping[bygning.avløp] || ikkeRegistrertVerdi
        : ikkeRegistrertMatrikkelverdi

    default:
      return ikkeRegistrertMatrikkelverdi
  }
}

export const ikkeRegistrertVerdi = "-----"

export const visEgenregistrertVerdi = (
  key: Felter,
  egenregistreringForBygning: EgenregistreringBygning,
  bruksenhetId: string,
) => {
  const bruksenhet = egenregistreringForBygning.bruksenheter.find(
    enhet => enhet.bruksenhetId.toString() === bruksenhetId,
  )
  if (!bruksenhet) return ikkeRegistrertVerdi

  switch (key) {
    case "byggeår":
      return bruksenhet.byggeår?.data ?? ikkeRegistrertVerdi
    case "bruksareal":
      return bruksenhet.bruksareal?.data ? `${bruksenhet.bruksareal.data} m²` : ikkeRegistrertVerdi
    case "vannforsyning":
      return bruksenhet.vannforsyning?.data
        ? Vannforsyningstype[bruksenhet.vannforsyning.data]
        : ikkeRegistrertVerdi
    case "avløp":
      return bruksenhet.avløp?.data ? Avløpstype[bruksenhet.avløp.data] : ikkeRegistrertVerdi
    case "energikilder": {
      const energikilder = bruksenhet.energikilder || []

      return energikilder.length > 0
        ? formaterMedKommaOgOg(energikilder.map(energikilde => Energikildetype[energikilde.data]))
        : ikkeRegistrertVerdi
    }
    case "oppvarming": {
      const oppvarmingstyper = bruksenhet.oppvarming || []
      return oppvarmingstyper.length > 0
        ? formaterMedKommaOgOg(oppvarmingstyper.map(oppvarming => Oppvarmingstype[oppvarming.data]))
        : ikkeRegistrertVerdi
    }
    default:
      return ikkeRegistrertVerdi
  }
}

export const getModalLabel = (field: keyof EgenregistreringSchema): FelterLabels => {
  switch (field) {
    case "byggeår":
      return "Byggeår"
    case "bruksareal":
      return "Totalt bruksareal"
    case "energikilder":
      return "Energikilder"
    case "oppvarming":
      return "Oppvarming"
    case "vannforsyning":
      return "Vannforsyning"
    case "avløp":
      return "Avløp"
    default:
      return ""
  }
}

export const felterForEgenregistrering: Array<{
  key: Felter
  label: FelterLabels
  icon: IkonForFelt
}> = [
  { key: "byggeår", label: getModalLabel("byggeår"), icon: "calendar_today" },
  { key: "bruksareal", label: getModalLabel("bruksareal"), icon: "square_foot" },
  { key: "vannforsyning", label: getModalLabel("vannforsyning"), icon: "water_drop" },
  { key: "avløp", label: getModalLabel("avløp"), icon: "valve" },
  { key: "energikilder", label: getModalLabel("energikilder"), icon: "bolt" },
  { key: "oppvarming", label: getModalLabel("oppvarming"), icon: "heat" },
]

export const harNøyaktigEnBruksenhetIBygning = (bruksenheterIBygning: Bruksenhet[]): boolean => {
  return bruksenheterIBygning.length === 1
}
