import { FormLabel, RadioGroup, Stack } from "@kvib/react"
import { UseFormRegister } from "react-hook-form"
import { Avløpstype, Vannforsyningstype } from "../types/Enums"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "../types/schema"
import Radio from "./Radio"

interface Props {
  name: "avløp" | "vannforsyning"
  register: UseFormRegister<EgenregistreringSchema>

  felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }
  options: typeof Vannforsyningstype | typeof Avløpstype
}

const VannforsyningOgAvløpRadioGroup = ({ name, options, felt, register }: Props) => {
  return (
    <>
      <RadioGroup name={name}>
        <FormLabel>Velg {felt.key} for bygningen</FormLabel>
        <Stack spacing={2} ml="12px">
          {Object.entries(options).map(([key, label]) => (
            <Radio key={key} feltKey={key} label={label} name={name} register={register} />
          ))}
        </Stack>
      </RadioGroup>
    </>
  )
}

export default VannforsyningOgAvløpRadioGroup
