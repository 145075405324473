import { eiendomRouter } from "../eiendom/detaljert-eiendom/api/eiendom"
import { eiendommerRouter } from "../eiendom/eiendomsoversikt/api/eiendommer"
import { kodelisterRouter } from "../eiendom/kodelister/kodelister"
import { egenregistreringRouter } from "../eiendom/registrering/api/egenregistrering"
import { interaktivtKartRouter } from "../interaktivtKart/api/interaktivtKart"
import { brukerRouter } from "../user/bruker"

export const api = {
  egenregistrering: egenregistreringRouter,
  eiendom: eiendomRouter,
  eiendommer: eiendommerRouter,
  bruker: brukerRouter,
  kart: interaktivtKartRouter,
  kodelister: kodelisterRouter,
}
