import { Button } from "@kvib/react"
import { Map, View } from "ol"
import { defaults as defaultControls } from "ol/control"
import { GeoJSON } from "ol/format"
import { defaults as defaultInteractions } from "ol/interaction"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import "ol/ol.css"
import { XYZ } from "ol/source"
import VectorSource from "ol/source/Vector"
import { usePostHog } from "posthog-js/react"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useScreenView } from "../core/hooks/useScreenview"
import { Koordinat } from "../eiendom/detaljert-eiendom/bygningsdetaljer/types/Bygning"
import useCurrentEiendom from "../eiendom/detaljert-eiendom/hooks/useCurrentEiendom"

import { ResetZoomControl } from "./components/ResetZoomControl"
import { KART_EXTENT, kartpadding, projeksjon } from "./constants"
import { Polygoner } from "./types/InteraktivtKart"
import { hentBboxForPolygoner, polygonTilGeoJSON, polygonstil } from "./utils"

export const InteraktivtKart = ({ teigpolygoner }: { teigpolygoner?: Polygoner }) => {
  const { eiendom, isEiendommerPending } = useCurrentEiendom()
  const { t } = useTranslation("eiendomskort")
  const posthog = usePostHog()
  const { isMobile } = useScreenView()

  const getKartutsnittLenke = (koordinat: Koordinat): string =>
    `#!?project=seeiendom&zoom=16&lat=${koordinat.lat}&lon=${koordinat.long}&markerLat=${koordinat.lat}&markerLon=${koordinat.long}&panel=Seeiendom&showSelection=true`

  const handleNavigateToNorgeskart = () => {
    if (eiendom?.koordinat) {
      const url = getKartutsnittLenke(eiendom.koordinat)
      posthog.capture("Ekstern lenke", {
        link_name: "Åpne i Norgeskart",
        tilAdresse: "https://norgeskart.no/",
      })
      window.open(`https://norgeskart.no/${url}`, "_blank")
    }
  }

  const NorgeskartKnapp = () => (
    <Button
      zIndex={1000}
      rightIcon="open_in_new"
      variant="primary"
      onClick={handleNavigateToNorgeskart}
      colorScheme="blue"
      position="absolute"
      right={2}
      bottom={2}
      iconAriaIsHidden
      size={isMobile ? "sm" : "md"}
    >
      {t("vis_i_kart_knapp")}
    </Button>
  )

  const overlays = useMemo(() => teigpolygoner?.map(p => polygonTilGeoJSON(p)), [teigpolygoner])
  const extent = useMemo(() => hentBboxForPolygoner(teigpolygoner), [teigpolygoner])

  useEffect(() => {
    if (!eiendom?.koordinat) return

    const viewConfig = {
      projection: projeksjon,
      maxZoom: 18,
      extent: KART_EXTENT,
      center: [eiendom.koordinat.long, eiendom.koordinat.lat],
      ...(!teigpolygoner && {
        zoom: 15,
      }),
    }

    const map = new Map({
      layers: [
        new TileLayer({
          source: new XYZ({
            crossOrigin: "*",
            projection: projeksjon,
            url: `https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/utm33n/{z}/{y}/{x}.png`,
          }),
        }),
      ],
      view: new View(viewConfig),
      target: "map",
      interactions: defaultInteractions({
        mouseWheelZoom: false,
      }),
      controls: defaultControls().extend([new ResetZoomControl(extent ?? KART_EXTENT)]),
    })

    if (extent) {
      map.getView().fit(extent, {
        size: map.getSize(),
        padding: Array(4).fill(kartpadding),
      })
    }
    overlays?.forEach(overlay => {
      const source = new VectorSource({
        features: new GeoJSON().readFeatures(overlay),
      })
      map.addLayer(
        new VectorLayer({
          source: source,
          style: polygonstil,
        }),
      )
    })
    return () => {
      map.setTarget(undefined)
    }
  }, [eiendom?.koordinat, extent, overlays, teigpolygoner])

  const Layer = () => {
    if (!eiendom?.koordinat) return null
    return <NorgeskartKnapp />
  }

  if (isEiendommerPending) {
    return null
  }

  return (
    <div id="map" tabIndex={1} style={{ height: "100%", width: "100%" }}>
      <Layer />
    </div>
  )
}
