import { Flex, Icon, Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useBruker } from "../../../user/hooks/useBruker"
import { Brødtekst } from "../../text/components/Brødtekst"

export const Brukerinfo = () => {
  const { data: bruker, isPending, isSuccess, isError } = useBruker()
  const { t } = useTranslation("navbar")

  if (isError)
    return (
      <Flex alignItems="center" color="green.500" gap="6px">
        <Flex alignItems="flex-end">
          <Icon ariaIsHidden icon="person" isFilled size={26} />
          <Brødtekst>{t("innlogget")}</Brødtekst>
        </Flex>
      </Flex>
    )

  if (isPending || !isSuccess) return <SkeletonBrukerinfo />

  const navn = `${bruker.fornavn} ${bruker.etternavn}`

  return (
    <Flex
      alignItems="center"
      color="green.500"
      gap="6px"
      aria-label={t("logget_inn_arialabel", { navn: navn })}
      role="heading"
    >
      <Icon ariaIsHidden icon="person" isFilled size={26} />
      <Brødtekst aria-hidden>{navn}</Brødtekst>
    </Flex>
  )
}

const SkeletonBrukerinfo = () => {
  const { t } = useTranslation("navbar")
  return (
    <Flex alignItems="center" color="green.500" gap="6px">
      <Skeleton>
        <Icon ariaIsHidden icon="person" isFilled size={26} />
      </Skeleton>
      <Skeleton>
        <Brødtekst>{t("navn_placeholder")}</Brødtekst>
      </Skeleton>
    </Flex>
  )
}
