import { format } from "date-fns"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { useMetadata } from "../hooks/useMetadata"
import { Felter } from "../types/schema"

const Registreringsinfo = ({ feltKey }: { feltKey: Felter }) => {
  const metadata = useMetadata(feltKey)

  if (!metadata) return null

  const registrertAv = (metadata: { registrertAvDeg: boolean }) => {
    return metadata.registrertAvDeg ? "deg" : "annen eier"
  }

  return (
    <Brødtekst
      litenVariant
      as="i"
    >{`Registrert av ${registrertAv(metadata)} ${format(metadata.registreringstidspunkt, "dd.MM.yyyy")}`}</Brødtekst>
  )
}

export default Registreringsinfo
