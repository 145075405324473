import { Box } from "@kvib/react"
import { Brødtekst } from "../../../core/text/components/Brødtekst"

const HjelpetekstForFelt = ({ children }: { children: string }) => {
  return (
    <Box mb="30px" bg="blue.50" borderRadius="8px">
      <Brødtekst p="18px">{children}</Brødtekst>
    </Box>
  )
}

export default HjelpetekstForFelt
