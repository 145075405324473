import { Flex } from "@kvib/react"
import { UseFormRegister } from "react-hook-form"
import { EgenregistreringSchema } from "../types/schema"

interface CheckboxProps {
  feltKey: string
  label: string
  name: keyof EgenregistreringSchema
  register: UseFormRegister<EgenregistreringSchema>
}
const CheckboxForEgenregistrering = (props: CheckboxProps) => {
  const { feltKey, label, name, register } = props
  return (
    <Flex>
      <input
        type="checkbox"
        id={feltKey}
        value={feltKey}
        style={{ width: "16px", accentColor: "green", marginRight: "4px" }}
        {...register(name)}
      />
      <label htmlFor={feltKey}>{label}</label>
    </Flex>
  )
}

export default CheckboxForEgenregistrering
