import { Box, Flex, Logo } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { Link, LinkProps } from "react-router-dom"
import { EksternLenke } from "../../text/components/EksternLenke"
import Section from "../layout/Section"

export const TilpassetFooter = () => {
  const { t } = useTranslation("footer")
  const linkStyle = {
    color: "#166630",
    fontWeight: 400,
    textDecoration: "underline",
  }

  const ThinLink = (props: LinkProps) => (
    <Link tabIndex={0} style={linkStyle} {...props}>
      {props.children}
    </Link>
  )

  return (
    <footer>
      <Section
        bg="gray.50"
        borderTop="4px solid #156630"
        pt={["40px", "28px"]}
        pb={["11px", "28px"]}
      >
        <Flex
          justifyContent="space-between"
          flexDir={{
            base: "column-reverse",
            xl: "row",
          }}
          alignItems={{
            base: "flex-start",
            xl: "center",
          }}
          gap="64px"
        >
          <Box aria-label="Kartverket-logo" role="img">
            <Box aria-hidden>
              <Logo variant="horizontal" size={148} />
            </Box>
          </Box>
          <Flex
            gap="16px"
            alignItems="flex-start"
            flexDir={{
              base: "column",
              xl: "row",
            }}
          >
            <ThinLink to="/om-tenesta">{t("om_tjenesten_link")}</ThinLink>
            <ThinLink to="/cookies">{t("cookieserklaering")}</ThinLink>
            <ThinLink to="/personvern">{t("personvern_og_sikkerhet_link")}</ThinLink>
            <EksternLenke
              aria-label={t("personvern_erklæring_arialabel")}
              tilAdresse="https://www.kartverket.no/om-kartverket/personvern"
              style={linkStyle}
              wordBreak="break-all"
            >
              {t("personvern_erklæring_link")}
            </EksternLenke>
            <EksternLenke
              aria-label={t("tilgjengelighetserklæring_arialabel")}
              tilAdresse="https://uustatus.no/nb/erklaringer/publisert/2e6690ac-9835-4079-9634-9810511b14ee"
              style={linkStyle}
              wordBreak="break-all"
            >
              {t("tilgjengelighetserklæring_link")}
            </EksternLenke>
          </Flex>
        </Flex>
      </Section>
    </footer>
  )
}
