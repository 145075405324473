import { Box, IconButton } from "@kvib/react"
import { Link } from "react-router-dom"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { InfoCard } from "./InfoCard"

interface InfoCardEksternLenkeProps {
  oversettelse: { tittel: string; avsnitt: string[] }
  lenke: string
  iconButtonArialabel: string
}
const InfoCardEksternLenke = (props: InfoCardEksternLenkeProps) => {
  const { oversettelse, lenke, iconButtonArialabel: iconButtonAriaLabel } = props

  return (
    <Box w={{ base: "100%", sm: "100%", md: "50%", lg: "50%" }}>
      <Link to={lenke} target="_blank">
        <InfoCard bg="gray.50" cardHeader={oversettelse.tittel} h="100%">
          <Box display="flex" flexDirection="column" h="100%">
            <Box flex="1">
              {oversettelse.avsnitt.map(avsnitt => (
                <Brødtekst key={avsnitt}>
                  {avsnitt}
                  <br />
                </Brødtekst>
              ))}
            </Box>
            <Box alignSelf="flex-end" mt="auto">
              <IconButton
                variant="tertiary"
                icon="arrow_right_alt"
                aria-label={iconButtonAriaLabel}
              />
            </Box>
          </Box>
        </InfoCard>
      </Link>
    </Box>
  )
}

export default InfoCardEksternLenke
