import { useTranslation } from "react-i18next"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import InputfeltTall from "./InputfeltTall"
import { InvalidInputAlert } from "./InvalidInputAlert"

const RegistrerByggeår = ({ feltnavn }: { feltnavn: "byggeår" }) => {
  const { t } = useTranslation("egenregistrering")

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_byggeår")}</HjelpetekstForFelt>
      <InputfeltTall name={feltnavn} placeholder="F. eks 2023" />
      <InvalidInputAlert felt={feltnavn} />
    </>
  )
}

export default RegistrerByggeår
