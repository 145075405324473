import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Avløpstype, enumToOptions } from "../types/Enums"
import { getModalLabel } from "../utils"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import { InvalidInputAlert } from "./InvalidInputAlert"
import VannforsyningOgAvløpRadioGroup from "./VannforsyningOgAvløpRadioGroup"

const RegistrerAvløp = ({ feltnavn }: { feltnavn: "avløp" }) => {
  const { t } = useTranslation("egenregistrering")
  const formContext = useFormContext()

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_avløp")}</HjelpetekstForFelt>
      <VannforsyningOgAvløpRadioGroup
        register={formContext.register}
        name={feltnavn}
        options={enumToOptions(Avløpstype) as typeof Avløpstype}
        felt={{
          key: feltnavn,
          label: getModalLabel(feltnavn),
          icon: "valve",
        }}
      />
      <InvalidInputAlert felt={feltnavn} />
    </>
  )
}

export default RegistrerAvløp
