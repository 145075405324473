import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Oppvarmingstype, enumToOptions } from "../types/Enums"
import { getModalLabel } from "../utils"
import Flervalg from "./Flervalg"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import { InvalidInputAlert } from "./InvalidInputAlert"

const RegistrerOppvarming = ({ feltnavn }: { feltnavn: "oppvarming" }) => {
  const formContext = useFormContext()
  const { t } = useTranslation("egenregistrering")

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_oppvarmingstyper")}</HjelpetekstForFelt>
      <Flervalg
        name={feltnavn}
        options={enumToOptions(Oppvarmingstype)}
        register={formContext.register}
        felt={{
          key: feltnavn,
          label: getModalLabel(feltnavn),
          icon: "heat",
        }}
      />
      <InvalidInputAlert felt={feltnavn} />
    </>
  )
}

export default RegistrerOppvarming
