import { Flex } from "@kvib/react"
import { t } from "i18next"
import { useScreenView } from "../../../core/hooks/useScreenview"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"
import useCurrentEiendom from "../hooks/useCurrentEiendom"
import SeeiendomButton from "./SeeiendomButton"

const FlereOpplysninger = () => {
  const isMobile = useScreenView()
  const { eiendom } = useCurrentEiendom()

  return (
    <>
      <Overskrift variant="h2">
        {t("flere_opplysninger_overskrift", { ns: "detaljertInnsyn" })}
      </Overskrift>

      {eiendom?.matrikkelnummer && (
        <Flex mt="24px" flexDir="column" gap={4}>
          <Brødtekst maxW={isMobile ? "100%" : "65%"}>
            {t("flere_opplysninger_på_se_eiendom", { ns: "detaljertInnsyn" }) as string}
          </Brødtekst>
          <SeeiendomButton matrikkelnummer={eiendom.matrikkelnummer} />
        </Flex>
      )}
    </>
  )
}

export default FlereOpplysninger
