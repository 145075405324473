import { Flex } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { harNøyaktigEnBruksenhetIBygning } from "../../../registrering/utils"
import useCurrentBygning from "../../hooks/useCurrentBygning"
import useCurrentEiendom from "../../hooks/useCurrentEiendom"
import { EidDetaljertEiendom } from "../../types/DetaljertEiendom"
import { Bygning } from "../types/Bygning"
import { AccordionInneIBygningskort } from "./AccordionInneIBygningskort"
import EgenskaperVedBygning from "./EgenskaperVedBygning"
import NøkkelinfoOmAlleBruksenheterIBygning from "./NøkkelinfoOmAlleBruksenheterIBygning"
import OmBygningen from "./OmBygningen"
import { erBortfestet } from "../../api/eiendom"

const visAccordionsIBygningskortIRiktigRekkefølge = (
  componentsOrder: string[],
  eiendom: EidDetaljertEiendom,
  bygning: Bygning,
  erEierseksjon: boolean,
  nøyaktigEnBruksenhetIBygning: boolean,
) => {
  return componentsOrder.map((component, index) => {
    const { t } = useTranslation(["bygningskort", "bruksenhetskort"])

    const bruksenhetsaccordionOverskrift = nøyaktigEnBruksenhetIBygning
      ? t("din_bruksenhet_i_bygningen_accordion_overskrift", {
          ns: "bruksenhetskort",
        })
      : t("dine_bruksenheter_i_bygningen_accordion_overskrift", {
          ns: "bruksenhetskort",
        })
    switch (component) {
      case "bruksenheter":
        return (
          <AccordionInneIBygningskort
            key={index}
            åpenFraStart={erEierseksjon}
            accordionTittel={bruksenhetsaccordionOverskrift}
          >
            <NøkkelinfoOmAlleBruksenheterIBygning eiendom={eiendom} byggId={bygning.byggId} />
          </AccordionInneIBygningskort>
        )
      case "omBygningen":
        return (
          <AccordionInneIBygningskort
            key={index}
            accordionTittel={t("bygningskort_overskrift")}
            åpenFraStart={!erEierseksjon}
          >
            <OmBygningen bygning={bygning} />
          </AccordionInneIBygningskort>
        )
      case "egenskaperVedBygningen":
        return (
          <AccordionInneIBygningskort
            key={index}
            accordionTittel={t("egenskaper_ved_bygningen_accordion_overskrift")}
          >
            <EgenskaperVedBygning bygning={bygning} />
          </AccordionInneIBygningskort>
        )
      default:
        return null
    }
  })
}

export const BygningskortInnhold = ({ bygningsId }: { bygningsId: string }) => {
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning(bygningsId)
  const { eiendom } = useCurrentEiendom()
  const erEierseksjon = eiendom?.matrikkelenhetstype === "Eierseksjon"
  const componentsOrder = erEierseksjon
    ? ["bruksenheter", "omBygningen", "egenskaperVedBygningen"]
    : ["omBygningen", "egenskaperVedBygningen", "bruksenheter"]

  if (!eiendom || erBortfestet(eiendom) || !currentBygning || !dineBruksenheterIBygning) return null
  const nøyaktigEnBruksenhetIBygning = harNøyaktigEnBruksenhetIBygning(dineBruksenheterIBygning)

  return (
    <Flex
      flexDir="column"
      columnGap={{ base: "16px", md: "48px", lg: "24px" }}
      flexWrap={{ base: "wrap", xl: "nowrap" }}
      justifyContent="space-between"
      m="16px"
    >
      {visAccordionsIBygningskortIRiktigRekkefølge(
        componentsOrder,
        eiendom,
        currentBygning,
        erEierseksjon ? eiendom.matrikkelenhetstype === "Eierseksjon" : false,
        nøyaktigEnBruksenhetIBygning,
      )}
    </Flex>
  )
}
