import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { EgenregistreringBygning } from "../types/Egenregistrering"
import { EgenregistreringSchema, egenregistreringSchema } from "../types/schema"

export const useEgenregistreringForm = (egenregistreringForBygning: EgenregistreringBygning) => {
  const form = useForm<EgenregistreringSchema>({
    resolver: zodResolver(egenregistreringSchema),
    reValidateMode: "onChange",
    shouldUnregister: true,
    values: {
      byggeår: egenregistreringForBygning?.bruksenheter?.[0]?.byggeår?.data,
      bruksareal: egenregistreringForBygning?.bruksenheter?.[0]?.bruksareal?.data,
      avløp: egenregistreringForBygning?.bruksenheter?.[0]?.avløp?.data,
      vannforsyning: egenregistreringForBygning?.bruksenheter?.[0]?.vannforsyning?.data,
      energikilder: egenregistreringForBygning?.bruksenheter?.[0]?.energikilder?.map(
        kilde => kilde?.data,
      ),
      oppvarming: egenregistreringForBygning?.bruksenheter?.[0]?.oppvarming?.map(
        oppvarming => oppvarming?.data,
      ),
    },
  })

  return form
}
