import { Flex, Skeleton, Tag } from "@kvib/react"
import { useTranslation } from "react-i18next"
import DetaljPopover from "../../components/DetaljPopover"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

export const BygningstyperDetalj = ({ unikeBygningstyper }: { unikeBygningstyper?: string[] }) => {
  const harRegistrerteBygninger = unikeBygningstyper && unikeBygningstyper.length > 0
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])

  return (
    <DetaljPopover
      forklaring={t("bygningstyper_på_eiendommen_forklaring") as string}
      buttonAriaLabel={t("bygningstyper_på_eiendommen_forklaring_arialabel") as string}
    >
      <OverskriftForEiendomsdetaljer>
        {t("bygningstyper_på_eiendommen_overskrift")}
      </OverskriftForEiendomsdetaljer>
      {unikeBygningstyper ? (
        <Flex mt="8px" flexWrap="wrap" gap="8px">
          {harRegistrerteBygninger ? (
            unikeBygningstyper.map(bygningstype => (
              <Tag colorScheme="green" key={bygningstype}>
                <FeltverdiForEiendomsdetaljer
                  noOfLines={2}
                  h="fit-content"
                  fieldValue={bygningstype}
                />
              </Tag>
            ))
          ) : (
            <FeltverdiForEiendomsdetaljer
              fieldValue={t("eiendommen_har_ingen_bygninger", { ns: "bygningskort" }) as string}
            />
          )}
        </Flex>
      ) : (
        <Skeleton
          w={{
            base: "25%",
            md: "80%",
          }}
          h={{
            base: "24px",
            md: "40px",
          }}
        />
      )}
    </DetaljPopover>
  )
}
