import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@kvib/react"
import { format } from "date-fns"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { getVegadresserPåBygning } from "../../detaljert-eiendom/bygningsdetaljer/utils/bygningerUtils"
import { hentBygningstype } from "../../detaljert-eiendom/bygningsdetaljer/utils/bygningstypeUtil"
import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import useCurrentEiendom from "../../detaljert-eiendom/hooks/useCurrentEiendom"
import { formaterFlereVegadresserForBygning } from "../../eiendomsoversikt/utils/adresseForEiendomUtils"
import useEgenregistrering from "../api/useEgenregistrering"
import { useMetadata } from "../hooks/useMetadata"
import { Felter, FelterLabels, IkonForFelt } from "../types/schema"
import { visEgenregistrertVerdi } from "../utils"
import CircularIcon from "./CircularIcon"
import { IkonOgFeltLabel } from "./IkonOgFeltLabel"

interface EgenregistreringBekreftelseModalProps {
  isOpen: boolean
  onClose: () => void
  isSuccess: boolean
  egenregistrertVerdi: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }
}

const EgenregistreringBekreftelseModal = ({
  isOpen,
  onClose,
  egenregistrertVerdi,
}: EgenregistreringBekreftelseModalProps) => {
  const { data: egenregistreringForBygning, isSuccess, isPending } = useEgenregistrering()
  const { dineBruksenheterIBygning, currentBygning } = useCurrentBygning()
  const { eiendom } = useCurrentEiendom()
  const metadata = useMetadata(egenregistrertVerdi.key)

  if (
    !isSuccess ||
    isPending ||
    !eiendom ||
    !egenregistreringForBygning ||
    !dineBruksenheterIBygning
  )
    return null

  if (!metadata) return null

  const vegadresse =
    eiendom?.adresser?.vegadresser && eiendom.adresser.vegadresser.length > 0
      ? formaterFlereVegadresserForBygning(
          getVegadresserPåBygning(
            eiendom.adresser.vegadresser,
            dineBruksenheterIBygning.map(enhet => enhet.bruksenhetsId),
          ),
        )
      : null

  const bygningstype = currentBygning?.bygningstype
    ? hentBygningstype(currentBygning?.bygningstype)
    : ""

  const ModalInnhold = () => {
    return (
      <>
        <Overskrift mt="20px" variant="h3" mb="16px">
          Din registrering ble lagret i Eiendomsregisteret
        </Overskrift>

        <Brødtekst>{` ${bygningstype}, ${vegadresse}`}</Brødtekst>

        <Divider mb="20px" mt="20px" />
        <Grid mt="20px" templateColumns="0.5fr 1fr" gap={5} alignItems="center">
          <GridItem>
            <IkonOgFeltLabel iconSize={18} felt={egenregistrertVerdi} />
          </GridItem>
          <GridItem>
            <Brødtekst>
              {visEgenregistrertVerdi(
                egenregistrertVerdi.key,
                egenregistreringForBygning,
                dineBruksenheterIBygning[0].bruksenhetsId,
              )}
            </Brødtekst>
          </GridItem>
          <GridItem>
            <Flex alignItems="center">
              <CircularIcon iconSize={18} icon="calendar_today" />
              <Brødtekst fontWeight="bold">Dato for registrering</Brødtekst>
            </Flex>
          </GridItem>
          <GridItem>
            <Brødtekst>{format(metadata.registreringstidspunkt, "dd.MM.yyyy")}</Brødtekst>
          </GridItem>
        </Grid>
        <Divider mt="24px" />
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent gap={2}>
        <ModalCloseButton />
        <ModalBody>
          <ModalInnhold />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Ok</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EgenregistreringBekreftelseModal
