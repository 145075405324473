import {
  Flex,
  FormLabel,
  HStack,
  InputProps,
  NumberInput,
  NumberInputField,
  Stack,
} from "@kvib/react"
import { useFormContext } from "react-hook-form"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { EgenregistreringSchema } from "../types/schema"

interface InputfeltTallProps extends InputProps {
  name: keyof EgenregistreringSchema
  placeholder: string
  erBruksarealFelt?: boolean
}

const InputfeltTall = (props: InputfeltTallProps) => {
  const { name, placeholder, isDisabled, variant, erBruksarealFelt: skalViseKvadratmeter } = props
  const { register } = useFormContext()
  return (
    <Flex w="fit-content" gap={8}>
      <Stack>
        <FormLabel>Skriv inn {props.name} for det du eier i bygningen: </FormLabel>
        <NumberInput isDisabled={isDisabled} variant={variant}>
          <HStack>
            {skalViseKvadratmeter && <Brødtekst>Totalt beregnet bruksareal: </Brødtekst>}
            <NumberInputField
              {...register(name, { valueAsNumber: true })}
              name={name}
              value={name}
              placeholder={placeholder}
              w="fit-content"
              sx={{
                _disabled: {
                  color: "black",
                },
              }}
            />
            {skalViseKvadratmeter && <Brødtekst>m2</Brødtekst>}
          </HStack>
        </NumberInput>
      </Stack>
    </Flex>
  )
}

export default InputfeltTall
