export const cookies = {
  "nb-NO": {
    tittel: "Bruk av cookies på mineiendom.kartverket.no",
    informasjonskapsler: {
      tittel: "Informasjonskapsler (cookies)",
      avsnitt: [
        "Informasjonskapsler er midlertidige filer som blir lagret på enheten din når du besøker en nettside. Du kan lese mer om hva informasjonskapsler er på nkom.no. ",
      ],
    },
    nødvendige_informasjonskapsler: {
      tittel: "Nødvendige informasjonskapsler",
      avsnitt: [
        "Når du besøker mineiendom.kartverket.no bruker vi noen få informasjonskapsler som gjør nettsiden sikker å bruke, og er nødvendige for at tjenesten skal fungere. Disse er derfor ikke mulig å velge bort.",
      ],
    },
    webanalyse: {
      tittel: "Webanalyse",
      avsnitt: [
        "Dersom du gir samtykke bruker vi noen informasjonskapsler for å samle inn statistikk om brukeraktivitet på nettsiden vår. Vi benytter verktøyet Posthog for å samle inn og analysere aktivitet for å forbedre tjenesten. Disse må du samtykke til å bruke. Funksjoanliteten på nettsiden påvirkes ikke dersom du lar være å samtykke. Tabellen med oversikt over cookies på mineiendom.kartverket.no viser hvilke informasjonskaplser som er nødvendige og hvilke som er valgfrie.",
      ],
    },
    oversikt_over_cookies_på_mineiendom: "Oversikt over cookies på mineiendom.kartverket.no",
    cookiestabell_header: ["Navn", "Formål", "Valgfrihet", "Utsteder"],
    cookiestabell: [
      {
        navn: "session_id",
        formal: "Autentisering",
        valgfrihet: "Nødvendig",
        utsteder: "Kartverket",
      },
      {
        navn: "ISTIO-STICKY",
        formal: "Infrastruktur",
        valgfrihet: "Nødvendig",
        utsteder: "Kartverket",
      },
      {
        navn: "ph_phc_{VisitorIdentifier}",
        formal: "Webanalyse",
        valgfrihet: "Valgfri",
        utsteder: "Posthog",
      },
      {
        navn: "distinct_id",
        formal: "Webanalyse",
        valgfrihet: "Valgfri",
        utsteder: "Posthog",
      },
    ],
    cookiesforklaring_tittel: "Mer informasjon om hver enkelt cookie",
    cookiesforklaring: [
      {
        tittel: "session_id",
        avsnitt: [
          "Brukes for autentisering. Nødvendig for sikker samhandlig med den felles innloggingsløsningen for offentlige tjenester, ID-porten.",
        ],
      },
      {
        tittel: "ISTIO-STICKY",
        avsnitt: [
          "Benyttes for å håndtere økten din i Kartverkets infrastruktur. Cookien er nødvendig.",
        ],
      },
      {
        tittel: "ph_phc_{VisitorIdentifier}",
        avsnitt: [
          "Identifikator vi benytter for å samle inn og analysere aktivitet for å forbedre tjenesten i verktøyet Posthog. ",
        ],
      },
      {
        tittel: "distinct_id",
        avsnitt: [
          "Identifikator vi benytter for å måle bruk av tjenesten i bruker- og analyseverktøyet Posthog.",
        ],
      },
    ],
    administrer_cookies: {
      tittel: "Administrer cookies",
      avsnitt: [
        "Du kan når som helst trekke tilbake samtykket ved å trykke “administrer cookies” under.",
      ],
    },
  },
  "nn-NO": {
    tittel: "Bruk av informasjonskapslar på mineiendom.kartverket.no",
    informasjonskapsler: {
      tittel: "Informasjonskapslar (cookies)",
      avsnitt: [
        "Informasjonskapslar er mellombelse filer som blir lagra på eininga di når du besøkjer ei nettside. Du kan lese meir om kva informasjonskapslar er på nkom.no.",
      ],
    },
    nødvendige_informasjonskapsler: {
      tittel: "Nødvendige informasjonskapslar",
      avsnitt: [
        "Når du besøkjer mineiendom.kartverket.no brukar vi nokre få informasjonskapslar som gjer nettsida trygg å bruke, og som er nødvendige for at tenesta skal fungere. Desse kan derfor ikkje veljast bort.",
      ],
    },
    webanalyse: {
      tittel: "Webanalyse",
      avsnitt: [
        "Dersom du gjev samtykke, brukar vi nokre informasjonskapslar for å samle inn statistikk om brukaraktivitet på nettsida vår. Vi nyttar verktøyet Posthog for å samle inn og analysere aktivitet for å forbetre tenesta. Desse må du samtykke til å bruke. Funksjonaliteten på nettsida blir ikkje påverka om du let vere å samtykke. Du kan når som helst trekkje tilbake samtykket ved å trykke på “administrer cookies”. Tabellen med oversikt over cookies på mineiendom.kartverket.no viser kva for informasjonskapslar som er nødvendige og kva for nokre som er valfrie.",
      ],
    },
    administrer_samtykke: "Administrer samtykke",
    administrer_cookies: "",
    oversikt_over_cookies_på_mineiendom: "Oversikt over cookies på mineiendom.kartverket.no",
    cookiestabell_header: ["Namn", "Føremål", "Valfridom", "Utstedar"],
    cookiestabell: [
      {
        namn: "session_id",
        formal: "Autentisering",
        valgfrihet: "Nødvendig",
        utsteder: "Kartverket",
      },
      {
        namn: "ph_phc_{VisitorIdentifier}",
        formal: "Webanalyse",
        valgfrihet: "Valfri",
        utsteder: "Posthog",
      },
      {
        namn: "distinct_id",
        formal: "Webanalyse",
        valgfrihet: "Valfri",
        utsteder: "Posthog",
      },
    ],
    cookiesforklaring_tittel: "Informasjon om kvar enkelt cookie",
    cookiesforklaring: [
      {
        tittel: "session_id",
        avsnitt: [
          "Blir brukt for autentisering. Nødvendig for trygg samhandling med den felles innloggingsløysinga for offentlege tenester, ID-porten.",
        ],
      },
      {
        tittel: "ISTIO-STICKY",
        avsnitt: [
          "Blir brukt for å handtere økta di i Kartverkets infrastruktur. Cookien er nødvendig. ",
        ],
      },
      {
        tittel: "ph_phc_{VisitorIdentifier}",
        avsnitt: [
          "Identifikator vi nyttar for å samle inn og analysere aktivitet for å forbetre tenesta i verktøyet Posthog. Cookien er valfri.",
        ],
      },
      {
        tittel: "distinct_id",
        avsnitt: [
          "Identifikator vi nyttar for å måle bruk av tenesta i brukar- og analyseverktøyet Posthog. Cookien er valfri.",
        ],
      },
    ],
  },
}
